
import { defineComponent, ref, onMounted } from "vue";

import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import { ElLoading } from "element-plus";
import moment from "moment";
import { Action } from "vuex-module-decorators";
import { object } from "yup/lib/locale";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "financialEducationList",
  components: {
    // moment,
  },

  async mounted() {
    this.init();
  },
  data() {
    // const schema = Yup.object({});
    const store = useStore();
    return {
      store,
      calculators: [],
    };
  },
  // setup() {
  //   return {};
  // },
  methods: {
    init() {
      setCurrentPageBreadcrumbs("Financial Education List", []);

      this.store.dispatch(Actions.GET_CALCULATORS).then(() => {
        this.calculators = this.store.getters.getCalculatorsData;
        console.log(this.calculators);
      });
    },
    moment: function () {
      return moment();
    },
    calculatorUrl(id, calcTypeId, calcTypeParentId) {
      const calculator_type =
        calcTypeParentId == 1 ? "financial-calculators" : "dmp-calculators";
      let calculator = "";

      switch (calcTypeId) {
        case 7:
          calculator = "hire-purchase";
          break;
        case 8:
          calculator = "credit-card";
          break;
        case 9:
          calculator = "housing-loan";
          break;
        case 10:
          calculator = "personal-loan";
          break;

        default:
          break;
      }

      return "#/" + calculator_type + "/" + calculator + "/" + id;
    },
    getHumanDate(date) {
      console.log(date);
      return moment(date).format("dddd DD/MM/YYYY h:mm:ss a");
    },
    onSubmit(values) {
      console.log(values);
    },
  },
});
